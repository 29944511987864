<template>
    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="form-group">
                <div class="col-md-3">
                    <label>Start Date</label>
                    <DatePicker :inputAttributes="{class: 'form-control'}" v-model="state.optionFields.startDate.val" />
                </div>
                <div class="col-md-3 form-group">
                    <label>End Date</label>
                    <DatePicker :inputAttributes="{class: 'form-control'}" v-model="state.optionFields.endDate.val" />
                </div>
                <div class="col-md-3 form-group">
                    <label>CDI Location</label>
                    <select class="form-control" v-model="state.optionFields.cdiLocationID.val">
                        <option value="0">All</option>
                        <option v-for="[cdiLocationID, cdiLocation] in cache.cdiLocationCache.CDI_LOCATIONS" :key="cdiLocationID" :value="cdiLocationID">{{ cdiLocation }}</option>
                    </select>
                </div>
                <div class="col-md-3 form-group">
                    <label>Territory</label>
                    <select class="form-control" v-model="state.optionFields.territoryID.val" >
                        <option value="0">All</option>
                        <option v-for="[territoryID, territory] in cache.territoryCache" :key="territoryID" :value="territoryID">{{ territory }}</option>
                    </select>
                </div>
                <div class="col-md-3 form-group">
                    <label>Date Strategy</label>
                    <select class="form-control" v-model="state.optionFields.strategy.val">
                        <option value="year_over_year">Year over year</option>
                        <option value="consecutive">Consecutive</option>
                    </select>
                </div>
                <div class="form-group col-md-3">
                    <label>Number of Periods</label>
                    <input type="number" class="form-control" v-model="state.optionFields.numberOfPeriods.val" />
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                    <label><input type='checkbox' v-model="state.optionFields.includeNational.val" :true-value="1" :false-value=null> Exclude National</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from '@/store/Report.store.js';
import cdiLocationCache from '@/cache/cdiLocation.cache.js';
import DatePicker from '../utils/DatePicker';

export default {
    name: "Options",
    components: {DatePicker},
    props: ['data'],
    data() {
        return {
            state: store.state,
            cache: {
                cdiLocationCache,
                territoryCache : []
            }
        }
    },
    methods: {
        getTerritories: function () {
            let scope = this;
            store.customAPI('menu_builder/sales_territories', {}).then(function(res) {
                scope.cache.territoryCache = res;
            });
        }
    },
    watch:{
        toggleHide: function () {
            this.state.toggleHide = this.toggleHide;
        }
    },
    created() {
        this.getTerritories();
    }
}
</script>

<style scoped>

</style>